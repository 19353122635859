export const docListHeaders = [];

export const doctorHeaders = [
  "Name",
  // "Status",
  // "Availability",
  "Speciality",
  "Contact Details",
  "Action",
];

export const inclinicHeaders = [
  "Token No.",
  "Name",
  "Contact Details",
  "Checked-In At",
  "Status",
];

export const bookedHeaders = [
  "Check-In OTP",
  "Name",
  "Contact Details",
  "Scheduled On",
  "Slot Timings",
  "Action",
];

export const completedHeaders = [
  "Name",
  "Contact Details",
  "Completed On",
  "Action",
];

export const cancelledHeaders = [
  "Name",
  "Contact Details",
  "Scheduled On",
  "Cancelled On",
];

export const dashAppointmentHeaders = [
  "Patient Name",
  "Doctor",
  "Contact Details",
];

export const appointmentHeaders = [
  "Check-In OTP",
  "Name",
  "Doctor",
  "Session Timings",
  "Contact Details",
  "Action",
];

export const allappointmentHeaders = [
  "Check-In OTP",
  "Name",
  "Contact Details",
  "Date",
  "Doctor",
  "Session Timings",
  "Action",
];

export const patientHeaders = [
  "Name",
  "Contact Details",
  "Last Appointment",
  "Action",
];
export const allpatientHeaders = [
  "Name",
  "Contact Details",
  "Action"
];

export const reportHeaders = [
  "Booking Date",
  "Uploaded On",
  "Doctor",
  "Comments",
  "Action",
];

export const relationHeaders = [
  "Name",
  "Relationship Type",
  "Contact Details",
  "Action",
];
