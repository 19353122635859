import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { Search } from "../assets/icons/Icons";
import {
  getBookingListByHospitalId,
  getBookingRangeByHospitalId,
} from "../lib/apis/booking";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { hitRefreshToken } from "../lib/apis/user";
import { getCookie, setCookie } from "../lib/utils/cookies";
import { useUserData } from "../lib/contexts/UserContext";
import {
  allappointmentHeaders,
  appointmentHeaders,
} from "../assets/data/headers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Booking } from "../lib/utils/types";
import RescheduleModal from "../components/organisms/modals/RescheduleModal";
import { modalStyles } from "../assets/styles/modalStyles";
import { Option } from "react-dropdown";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

interface ExcelData {
  Name: string;
  Contact_Details: string;
  Last_Appointment: string;
}

const Appointments = () => {
  const id = getRouteSegment(1);
  const navigate = useNavigate();
  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, hospData } = useHospDocData();
  const { appointmentsData, setAppointmentsData } = usePatientBooking();
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [availability_id, setAvailabilityId] = useState<string>();
  const [mapping_id, setMappingId] = useState<string>();
  const [bookingId, setBookingId] = useState<string>();
  const [search, setSearch] = useState("");
  const [allAppointments, setAllAppointments] = useState<
    Booking[] | undefined
  >();

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  function filterUniqueBookings(bookings: Booking[]): Booking[] {
    const uniqueBookings = new Map<string, Booking>();
    bookings.forEach((booking) => {
      if (!uniqueBookings.has(booking.booking_id)) {
        uniqueBookings.set(booking.booking_id, booking);
      }
    });
    return Array.from(uniqueBookings.values());
  }

  useEffect(() => {
    const fetchAllAppointments = async () => {
      const appointment_data = await getBookingRangeByHospitalId({
        hospital_id: hospitalID,
        startDate: moment().add(0, "days").format("YYYY-MM-DD"),
        endDate: "2024-12-30",
        doctor_id: "",
      });

      if (appointment_data?.status === 200) {
        setAllAppointments(filterUniqueBookings(appointment_data.data.result));
      } else {
        setAllAppointments(undefined);
      }
    };
    fetchAllAppointments();
  }, [rescheduleVisible, setAppointmentsData, mapping_id]);

  useEffect(() => {
    const patientsData = async () => {
      const res = await getBookingListByHospitalId(
        hospitalID,
        userData?.doctor_id
      );
      if (res?.status === 200) {
        setAppointmentsData(res.data.result);
        console.log("appointmentsData", res.data.result);
      } else if (res?.status === 403 && accessToken && refreshToken) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getBookingListByHospitalId(
            hospitalID,
            userData?.doctor_id
          );
          if (res?.status === 200) setAppointmentsData(res.data.result);
        } else {
          setAppointmentsData(undefined);
        }
      } else {
        setAppointmentsData(undefined);
      }
    };

    patientsData();
  }, [hospData, rescheduleVisible, setAppointmentsData, mapping_id]);

   function calculateAge(dob: string) {
     // Parse the date of birth
     const birthDate = new Date(dob);
     const today = new Date();

     // Calculate the age
     let age = today.getFullYear() - birthDate.getFullYear();
     const monthDifference = today.getMonth() - birthDate.getMonth();

     // Adjust age if the birthday hasn't occurred yet this year
     if (
       monthDifference < 0 ||
       (monthDifference === 0 && today.getDate() < birthDate.getDate())
     ) {
       age--;
     }

     return age;
   }


 const header = [
   "Patient Name",
   "Age",
   "Gender",
   "Schedule Date",
   "Remark",
   "Doctor Name",
   "Booked Date",
   "Appointment Status",
 ];

 function handleDownloadExcel() {
   if (!appointmentsData || !allAppointments) {
     toast.error("No appointments found");
     return;
   }

   // Helper function to format the date properly
   const formatDate = (dateString:string) => {
     if (!dateString) return "";
     return new Date(dateString).toISOString().slice(0, 10); // Format as YYYY-MM-DD
   };

   // Transform appointmentsData into an array of arrays for Sheet1
   const transformedDataSheet1 = appointmentsData.map(
     ({
       patient_full_name,
       patient_dob,
       gender,
       booked_date,
       comments,
       doctor_full_name,
       booking_time,
       booking_mode,
     }) => [
       patient_full_name || "",
       calculateAge(String(patient_dob)) || 0,
       gender || "",
       formatDate(booked_date) || "",
       comments || "",
       doctor_full_name || "",
       formatDate(booking_time) || "",
       booking_mode || "",
     ]
   );

   // Transform allAppointments into an array of arrays for Sheet2
   const transformedDataSheet2 = allAppointments.map(
     ({
       patient_full_name,
       patient_dob,
       gender,
       booked_date,
       comments,
       doctor_full_name,
       booking_time,
       booking_mode,
     }) => [
       patient_full_name || "",
       calculateAge(String(patient_dob)) || 0,
       gender || "",
       formatDate(booked_date) || "",
       comments || "",
       doctor_full_name || "",
       formatDate(booking_time) || "",
       booking_mode || "",
     ]
   );

   // Create a new workbook
   const wb = XLSX.utils.book_new();

   // Create a new worksheet for Sheet1
   if (transformedDataSheet1.length > 0) {
     const ws1 = XLSX.utils.aoa_to_sheet([header, ...transformedDataSheet1]);
     XLSX.utils.book_append_sheet(wb, ws1, "Today's Appointment");
   }

   // Create a new worksheet for Sheet2
   if (transformedDataSheet2.length > 0) {
     const ws2 = XLSX.utils.aoa_to_sheet([header, ...transformedDataSheet2]);
     XLSX.utils.book_append_sheet(wb, ws2, "All Appointments");
   }

   // Write the workbook to a file and download
   XLSX.writeFile(
     wb,
     `${hospData?.hospital_name || "Hospital"}_Appointments.xlsx`
   );
 }



  const tabsConfig = [
    {
      label: "Today's Appointments",
      count:
        "(" +
        (appointmentsData
          ? appointmentsData.filter(
              (item) =>
                item.reference_id
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_full_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_contact_number.includes(search.toLowerCase())
            ).length
          : "0") +
        ")",
      content:
        (appointmentsData &&
          appointmentsData.filter(
            (item) =>
              item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
              item.patient_full_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.patient_contact_number.includes(search.toLowerCase())
          ).length === 0) ||
        appointmentsData === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <div className="min-h-[45vh] md:min-h-auto max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
            <Table
              type="appointments"
              headers={appointmentHeaders}
              bookings={
                search
                  ? appointmentsData?.filter(
                      (item) =>
                        item.reference_id
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name.includes(search.toLowerCase())
                    )
                  : appointmentsData
              }
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              setMappingId={setMappingId}
              setAvailabilityId={setAvailabilityId}
            />
          </div>
        ),
    },
    {
      label: "All Appointments",
      count:
        "(" +
        (allAppointments
          ? allAppointments.filter(
              (item) =>
                item.reference_id
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_full_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_contact_number.includes(search.toLowerCase())
            ).length
          : "0") +
        ")",
      content:
        (allAppointments &&
          allAppointments.filter(
            (item) =>
              item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
              item.patient_full_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.patient_contact_number.includes(search.toLowerCase())
          ).length === 0) ||
        allAppointments === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <div className="min-h-[45vh] md:min-h-auto max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
            <Table
              type="allappointments"
              headers={allappointmentHeaders}
              bookings={
                search
                  ? allAppointments?.filter(
                      (item) =>
                        item.reference_id
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name.includes(search.toLowerCase())
                    )
                  : allAppointments
              }
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              setMappingId={setMappingId}
              setAvailabilityId={setAvailabilityId}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Appointments"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex justify-end items-center m-3 md:pr-5 md:mt-0 md:mb-[7px]">
              <button
                className="downloadBtn bg-sbTextHover hover:bg-queueHover opacity-80"
                onClick={handleDownloadExcel}
              >
                <svg
                  className="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span className="icon2"></span>
                <span className="tooltip">Download Excel</span>
              </button>
              <button
                className="rounded-lg opacity-80 px-0 md:w-60 py-2 text-white bg-sbTextHover hover:bg-queueHover mr-3"
                onClick={() =>
                  navigate(
                    "/" + hospitalID + "/appointments/book-an-appointment"
                  )
                }
              >
                Create Appointment
              </button>
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder bg-white md:mr-5">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
      </div>
      <RescheduleModal
        modalIsOpen={rescheduleVisible}
        closeModal={() => {
          setTimeout(() => {
            setRescheduleVisible(false);
          }, 500);
        }}
        customStyles={modalStyles}
        session={availability_id}
        booking_id={bookingId}
        mappingId={mapping_id}
        type="each"
        booking_ids={[]}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Appointments;
