import React, { useState, useEffect } from "react";
import ReactDropdown, { Option } from "react-dropdown";

import AdjacentDates from "../components/atoms/buttons/AdjacentDates";
import DocDetailsCard from "../components/molecules/DocDetailsCard";
import QueueEmpty from "../components/molecules/QueueEmpty";
import AddBookingModal from "../components/organisms/modals/AddBookingModal";

import {
  getDate,
  getDatesAroundToday,
  getDatesFromSunToSat,
  getRouteSegment,
} from "../lib/utils/funcs";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";

import { completedHeaders, cancelledHeaders } from "../assets/data/headers";
import { LeftArrow, RightArrow } from "../assets/icons/Icons";
import "../assets/styles/table.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { modalStyles } from "../assets/styles/modalStyles";
import { toast } from "react-toastify";
import { useInterval } from "../lib/utils/useInterval";
import Loader from "../components/molecules/Loader";
import FollowUpModal from "../components/organisms/modals/FollowUpModal";
import RescheduleModal from "../components/organisms/modals/RescheduleModal";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getCookie, setCookie } from "../lib/utils/cookies";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { Booking, DocAvailability } from "../lib/utils/types";
import { hitRefreshToken } from "../lib/apis/user";
import { useUserData } from "../lib/contexts/UserContext";
import {
  checkAvailability,
  getBookingListByAvailabilityId,
  getOnGoing,
  getSessionBookingByAvailabilityId,
} from "../lib/apis/booking";
import { getDoctorAvailability } from "../lib/apis/doctor";
import { estimateVisitTime } from "../lib/utils/constants";
import UploadReport from "../components/organisms/modals/UploadReport";
import { SessionAdjustment } from "../services/types";
import PaymentModal from "../components/organisms/modals/PaymentModal";
import EditPatientModal from "../components/organisms/modals/EditPatientModal";
import PrescriptionModal from "../components/organisms/modals/PrescriptionModal";
import * as XLSX from "xlsx";

interface SessionBooking {
  appointment_date: string; // "YYYY-MM-DD"
  appointment_start_time: string; // "HH:MM:SS"
  appointment_end_time: string; // "HH:MM:SS"
  appointment_notes: string;
  appointment_symptoms: string;
  booking_created_by: string; // email of the user who created the booking
  booking_creation_timestamp: string; // "YYYY-MM-DD HH:MM:SS"
  booking_id: string; // UUID
  booking_mode: string; // e.g., "booking"
  booking_status: string; // e.g., "Booked", "Cancelled"
  doctor_full_name: string; // Doctor's full name
  doctor_session_start_time: string; // Doctor's session start time in "HH:MM:SS"
  doctor_session_end_time: string; // Doctor's session end time in "HH:MM:SS"
  hospital_name: string; // Name of the hospital or clinic
  patient_contact_number: string; // Phone number as a string to support international format
  patient_full_name: string; // Patient's full name
  patient_id: string; // UUID for patient
  status_last_updated_at: string; // "YYYY-MM-DD HH:MM:SS"
  local_patient_id: string | null;
  patient_gender: string;
  patient_dob: string;
}

const EachDoctor = () => {
  const id = getRouteSegment(1);
  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");
  const navigate = useNavigate();
  const location = useLocation();

  const [week, setWeek] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [followUpVisible, setFollowUpVisible] = useState(false);
  const [prescriptionVisible, setPrescriptionVisible] = useState(false);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [options, setOptions] = useState<Option[]>();
  const [sessionBooking, setSessionBooking] = useState<SessionBooking[]>();
  const [session, setSession] = useState<Option>();
  const [docAvail, setDocAvail] = useState<DocAvailability[]>();
  const [bookingId, setBookingId] = useState<string>();
  const [patientId, setPatientId] = useState<string>();
  const [queue_type, setQueue_type] = useState("");

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, docDetails, setDocDetails } =
    useHospDocData();
  const {
    bookings,
    setBookings,
    SelectedDate,
    setSelectedDate,
    index,
    setIndex,
  } = usePatientBooking();

  const param = useParams();
  const mapping_id = String(param.mapping_id);

  useEffect(() => {
    setHospitalID(id);
  }, [id]);
  console.log(bookings);

  const fetchBookingsData = async (id: string) => {
    if (id !== "undefined") {
      setLoading(true);
      const booked_data = await getBookingListByAvailabilityId(
        id,
        SelectedDate
      );
      if (booked_data?.status === 200) {
        setBookings(booked_data.data.result);
        setLoading(false);
      } else {
        setBookings(undefined);
        setLoading(false);
      }
    }
  };
  const header = [
    "Patient Name",
    // "MRN",
    "Age",
    "Gender",
    "Schedule Date",
    "Schedule Time",
    "Remark",
    "Doctor Name",
    "Booked Date",
    "Appointment Status",
    "Created By",
  ];

   function calculateAge(dob: string) {
     // Parse the date of birth
     const birthDate = new Date(dob);
     const today = new Date();

     // Calculate the age
     let age = today.getFullYear() - birthDate.getFullYear();
     const monthDifference = today.getMonth() - birthDate.getMonth();

     // Adjust age if the birthday hasn't occurred yet this year
     if (
       monthDifference < 0 ||
       (monthDifference === 0 && today.getDate() < birthDate.getDate())
     ) {
       age--;
     }
     

     return age;
   }

  function handleDownloadExcel() {
    // Ensure sessionBooking exists
    if (sessionBooking) {
      console.log(sessionBooking);

      // Transform sessionBooking into an array of arrays
      const transformedData = sessionBooking.map(
        ({
          doctor_full_name,
          patient_full_name,
          appointment_date,
          appointment_notes,
          booking_created_by,
          booking_creation_timestamp,
          booking_status,
          patient_gender,
          patient_dob,
          appointment_start_time,
          appointment_end_time
        }) => [
          patient_full_name || "",
          calculateAge(patient_dob),
          patient_gender || "",
          appointment_date || "",
          appointment_start_time + " - " + appointment_end_time,
          appointment_notes || "",
          doctor_full_name || "",
          booking_creation_timestamp || "",
          booking_status || "",
          booking_created_by || "",
        ]
      );

      // Combine header and data
      const worksheetData = [header, ...transformedData];

      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

      // Generate Excel file and trigger download
      const fileName = `${docDetails?.full_name}_${
        session?.label || "session"
      }.xlsx`;
      XLSX.writeFile(workbook, fileName);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (docAvail) {
        const res = await getSessionBookingByAvailabilityId(
          session?.value,
          SelectedDate
        );
        setSessionBooking(res.data.result);
      }
    };
    fetchData();
  }, [session, rescheduleVisible, SelectedDate, docAvail,bookings]);

  useEffect(() => {
    const fetchDocAvailability = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        setDocAvail(res.data.result.doctor_availability);
        setDocDetails(res.data.result.doctor_details);
      } else if (res?.status === 403 && accessToken && refreshToken) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getDoctorAvailability(mapping_id);
          if (res?.status === 200) {
            setDocAvail(res.data.result.doctor_availability);
            setDocDetails(res.data.result.doctor_details);
          }
        }
      }
    };
    fetchDocAvailability();
  }, [mapping_id, userData]);

  const [data, setData] = useState({
    mapping_id: "",
    date: SelectedDate,
    session: "",
  });

  useEffect(() => {
    setData({
      mapping_id,
      date: SelectedDate,
      session: String(session?.value),
    });
    console.log("Session->", options);
  }, [session, bookings]);

  useEffect(() => {
    if (docAvail !== undefined) {
      const data: Option[] = docAvail
        .filter((i) => i.day_of_week === index)
        .map((item) => {
          return {
            value: String(item.availability_id),
            label: `${moment(item.start_time, "HH:mm:ss").format(
              "hh:mmA"
            )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,
            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
              max_patients: item.max_patients_per_slot,
            },
            start_time: item.start_time,
          };
        })
        .sort((a, b) => {
          // Sort by ascending order of time
          return moment(a.start_time, "HH:mm:ss").diff(
            moment(b.start_time, "HH:mm:ss")
          );
        });

      const fetchExtension = async () => {
        let newOptions = data.slice();

        for (let i = 0; i < data.length; i++) {
          try {
            const res = await checkAvailability(data[i].value, SelectedDate);
            console.log("checkAvailability", res?.data.result);

            if (res?.status === 200 && res.data.result.length > 0) {
              const api_data: SessionAdjustment[] = res.data.result;

              api_data.forEach((apiResult) => {
                if (apiResult.type === "Unavailability") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: "Unavailable Session",
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                } else if (apiResult.type === "Extension") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: `${moment(
                          apiResult.session_start_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")} - ${moment(
                          apiResult.extended_end_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")}`,
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                }
              });
            } else if (res?.status === 404) {
              console.log(
                "Session ID " + data[i].value + " not found, no changes made."
              );
            } else {
              throw new Error("Unhandled response status: " + res.status);
            }
          } catch (error) {
            console.error("Error fetching session availability: ", error);
          }
        }

        setOptions(newOptions.filter((e) => e.label !== "Unavailable Session"));

        const now = moment();
        // const now = moment().set({ hour: 16, minute: 0 });
        console.log(newOptions);
        const currSession = newOptions
          .filter((item) => {
            const startTime = moment(
              String(item?.label)?.split(" - ")[0],
              "HH:mmA"
            ).subtract(30, "minutes"); // Subtract 30 minutes from start time
            const endTime = moment(
              String(item?.label)?.split(" - ")[1],
              "HH:mmA"
            ).add(30, "minutes"); // Add 30 minutes to end time

            return now.isBetween(startTime, endTime); // Check if current time is within the modified range
          })
          .map((item) => {
            return {
              value: String(item.value),
              label: `${moment(
                String(item?.label)?.split(" - ")[0],
                "HH:mm:ss"
              ).format("hh:mmA")} - ${moment(
                String(item?.label)?.split(" - ")[1],
                "HH:mm:ss"
              ).format("hh:mmA")}`,
              data: {
                wait_time: item.data?.wait_time,
                queue_type: item.data?.queue_type,
              },
            };
          });

        console.log(currSession);
        if (currSession && currSession.length > 0) {
          const updatedSession = newOptions.find(
            (opt) => opt.value === currSession[0].value
          );
          setSession(updatedSession || newOptions[0]);
          setQueue_type(
            updatedSession
              ? String(updatedSession?.data?.queue_type)
              : String(newOptions[0]?.data?.queue_type)
          );
          fetchBookingsData(updatedSession?.value || newOptions[0].value);
        } else if (newOptions.length > 0) {
          setSession(newOptions[0]);
          setQueue_type(String(newOptions[0]?.data?.queue_type));
          fetchBookingsData(newOptions[0].value);
        } else {
          console.log("No available sessions found");
          setOptions([]);
          // Optionally, handle the case when all sessions are unavailable or removed
        }
      };

      fetchExtension();
    }
  }, [, SelectedDate, docAvail]);

  useEffect(() => {
    if (location.state !== null) {
      setSelectedDate(moment(location.state.selectedDate).format("YYYY-MM-DD"));
      setIndex(moment(location.state.selectedDate).day() + 1);
    } else {
      setSelectedDate(moment().format("YYYY-MM-DD"));
      setIndex(moment().day() + 1);
    }
  }, []);

  useInterval(async () => {
    const sesh = String(session?.value);
    const api_data = await getOnGoing(mapping_id, sesh, SelectedDate);
    const bCount = bookings ? bookings.length : 0;
    if (api_data?.status === 200) {
      const total_bookings = Number(api_data.data.result.total_bookings);
      let apiOnGoing;
      let inclinic_completed_count;
      if (api_data?.status === 200) {
        apiOnGoing = api_data.data.result.curr_booking_id;
        inclinic_completed_count = Number(
          api_data.data.result.inclinic_completed_count
        );
        const onGoingID =
          bookings && bookings?.filter((i) => i.status === 2)[0]
            ? bookings?.filter((i) => i.status === 2)[0].booking_id
            : null;
        const qCount =
          bookings && bookings.filter((i) => i.status === 1 || i.status === 2)
            ? bookings.filter((i) => i.status === 1 || i.status === 2).length
            : 0;
        const cCount =
          bookings && bookings.filter((i) => i.status === 3)
            ? bookings.filter((i) => i.status === 3).length
            : 0;
        if (onGoingID !== apiOnGoing) {
          // console.log("IOT button");
          fetchBookingsData(sesh);
        }
        if (inclinic_completed_count !== qCount + cCount) {
          fetchBookingsData(sesh);
        }
      } else {
        console.log("No onGoing");
      }

      console.log("booked", bCount, total_bookings);
      if (bCount !== total_bookings) {
        fetchBookingsData(sesh);
      }
    } else if (api_data?.status === 403 && accessToken && refreshToken) {
      const refresh_data = await hitRefreshToken(accessToken, refreshToken);
      if (refresh_data?.status === 200) {
        console.log("Refresh");
        setCookie("accessToken", refresh_data.data.result.access_token, 30);
        setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
      }
    }
  }, 5000);

  const changeOption: ((arg: Option) => void) | undefined = async (
    arg: Option
  ) => {
    setBookings(undefined);
    setSession(arg);
    console.log(
      "queue_type",
      options?.filter((session) => session.value === arg.value)[0].data
        ?.queue_type
    );
    setQueue_type(
      String(
        options?.filter((session) => session.value === arg.value)[0].data
          ?.queue_type
      )
    );
    fetchBookingsData(String(arg.value));
  };

  const inclinicHeaders = [
    queue_type === "Token" || queue_type === "Slot_Token"
      ? "Token No."
      : queue_type === "Slot"
      ? "Slot Timings"
      : "",
    "Name",
    "Contact Details",
    "Checked-In At",
    "Type",
    "Status",
  ];

  const bookedHeaders = [
    queue_type === "Token" ? "Token" : "Check-In OTP",
    "Name",
    "Contact Details",
    queue_type !== "Token" && queue_type !== "Session"
      ? estimateVisitTime.includes(String(hospitalID))
        ? "Estimate Visit Time"
        : "Slot Timings"
      : "Scheduled On",
    "Action",
  ];

  const followupHeaders = [
    "Check-In OTP",
    "Name",
    "Contact Details",
    queue_type !== "Token" && queue_type !== "Session"
      ? estimateVisitTime.includes(String(hospitalID))
        ? "Estimate Visit Time"
        : "Slot Timings"
      : "Scheduled On",
    "Action",
  ];

  const tabsConfig = [
    {
      label: "No Show",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 0).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 0).length === 0 ? (
          <QueueEmpty text={"No-show patients unavailable"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders.slice(0, 3)}
              bookings={bookings?.filter((item) => item.status === 0)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Completed",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 1 || item.status === 2 || item.status === 3
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 1 || item.status === 2 || item.status === 3
        ).length === 0 ? (
          <QueueEmpty text={"No completed patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="completed"
              headers={completedHeaders}
              bookings={bookings?.filter(
                (item) =>
                  item.status === 1 || item.status === 2 || item.status === 3
              )}
              availability_id={session?.value}
              setVisible={setFollowUpVisible}
              setPrescriptionVisible={setPrescriptionVisible}
              setUpload={setUploadVisible}
              setBookingID={setBookingId}
              setPatientID={setPatientId}
              setPayment={setPaymentVisible}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  const todayTabsConfig = [
    {
      label: "In Clinic",
      count:
        "(" +
        (bookings
          ?.sort((a, b) => a.token_number - b.token_number)

          .filter((item) => item.status === 1 || item.status === 2).length ||
          "0") +
        ")",
      content:
        bookings === undefined ||
        bookings.filter((item) => item.status === 1 || item.status === 2)
          .length === 0 ? (
          <QueueEmpty text={"No patients in the clinic"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="inclinic"
              headers={inclinicHeaders}
              bookings={bookings
                ?.filter((item) => item.status === 1 || item.status === 2)
                .sort((a, b) => a.token_number - b.token_number)}
              availability_id={session?.value}
              queue_type={queue_type}
              setVisible={setEditVisible}
              setFollowUpVisible={setFollowUpVisible}
              setBookingID={setBookingId}
            />
          </div>
        ),
    },
    {
      label: "Booked",
      count:
        "(" +
        (bookings
          ?.sort((a, b) => a.token_number - b.token_number)
          .filter(
            (item) => item.status === 0 && item.booking_mode === "booking"
          ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length === 0 ? (
          <QueueEmpty text={"No booked patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="booked"
              headers={bookedHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "booking"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setUpload={setEditVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Follow Up",
      count:
        "(" +
        (bookings?.filter(
          (item) =>
            item.status === 0 &&
            (item.booking_mode === "followup" ||
              item.booking_mode === "follow-up")
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) =>
            item.status === 0 &&
            (item.booking_mode === "followup" ||
              item.booking_mode === "follow-up")
        ).length === 0 ? (
          <QueueEmpty text={"No follow up patients"} />
        ) : (
          <div className="min-h-[45vh] md:min-h-auto md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[45vh] overflow-y-auto">
            <Table
              type="followup"
              headers={followupHeaders}
              bookings={bookings?.filter(
                (item) =>
                  item.status === 0 &&
                  (item.booking_mode === "followup" ||
                    item.booking_mode === "follow-up")
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Completed",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 3).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 3).length === 0 ? (
          <QueueEmpty text={"No completed patients"} />
        ) : (
          <div className="min-h-[45vh] md:min-h-auto md:min-h-auto md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="completed"
              headers={completedHeaders}
              bookings={bookings?.filter((item) => item.status === 3)}
              availability_id={session?.value}
              setVisible={setFollowUpVisible}
              setPrescriptionVisible={setPrescriptionVisible}
              setUpload={setUploadVisible}
              setPayment={setPaymentVisible}
              setBookingID={setBookingId}
              setPatientID={setPatientId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  const futureTabsConfig = [
    {
      label: "Booked",
      count:
        "(" +
        (bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) => item.status === 0 && item.booking_mode === "booking"
        ).length === 0 ? (
          <QueueEmpty text={"No booked patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="booked"
              headers={bookedHeaders}
              bookings={bookings?.filter(
                (item) => item.status === 0 && item.booking_mode === "booking"
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setUpload={setEditVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Follow Up",
      count:
        "(" +
        (bookings?.filter(
          (item) =>
            item.status === 0 &&
            (item.booking_mode === "followup" ||
              item.booking_mode === "follow-up")
        ).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter(
          (item) =>
            item.status === 0 &&
            (item.booking_mode === "followup" ||
              item.booking_mode === "follow-up")
        ).length === 0 ? (
          <QueueEmpty text={"No follow up patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="followup"
              headers={followupHeaders}
              bookings={bookings?.filter(
                (item) =>
                  item.status === 0 &&
                  (item.booking_mode === "followup" ||
                    item.booking_mode === "follow-up")
              )}
              availability_id={session?.value}
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              queue_type={queue_type}
            />
          </div>
        ),
    },
    {
      label: "Cancelled",
      count:
        "(" +
        (bookings?.filter((item) => item.status === 4).length || "0") +
        ")",
      content:
        bookings === undefined ||
        bookings?.filter((item) => item.status === 4).length === 0 ? (
          <QueueEmpty text={"No cancelled patients"} />
        ) : (
          <div className="md:min-h-auto min-h-[45vh] md:min-h-auto max-h-[45vh] md:max-h-[70vh] lg:max-h-[55vh] overflow-y-auto">
            <Table
              type="cancelled"
              headers={cancelledHeaders}
              bookings={bookings?.filter((item) => item.status === 4)}
              availability_id={session?.value}
              queue_type={queue_type}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header docData={docDetails} data={data} />
      <div className="flex flex-col md:flex-row py-6 md:pl-7 md:pr-8">
        <DocDetailsCard docData={docDetails} sessions={options} />
        <div className="bg-white p-2 rounded-lg mt-4 mx-5 md:hidden flex flex-row justify-between items-center">
          {week !== -10 && (
            <button onClick={() => setWeek(week - 5)}>
              <LeftArrow />
            </button>
          )}
          {getDatesAroundToday(week).map((val, index) => (
            <AdjacentDates
              key={index}
              tareek={val.tareek}
              day={val.day}
              date={val.date}
              onPress={() => {
                setSelectedDate(val.date);
                setIndex(moment(val.date).day() + 1);
                console.log(val.date);
                setLoading(true);
              }}
              isSelected={SelectedDate === val.date}
            />
          ))}
          {week !== 10 && (
            <button onClick={() => setWeek(week + 5)}>
              <RightArrow />
            </button>
          )}
        </div>
        <div className="bg-white px-6 py-4 rounded-lg mt-4 md:mt-0 md:ml-5 hidden md:flex flex-col justify-center items-center md:w-2/3">
          <p className="text-dark mb-3 font-medium">
            {moment(SelectedDate).format("MMMM D")}
          </p>
          <div className="flex flex-row w-full justify-between">
            {week !== -2 && (
              <button onClick={() => setWeek(week - 1)}>
                <LeftArrow />
              </button>
            )}
            {getDatesFromSunToSat(week).map((val, index) => (
              <AdjacentDates
                key={index}
                tareek={val.tareek}
                day={val.day}
                date={val.date}
                onPress={() => {
                  setSelectedDate(val.date);
                  setIndex(moment(val.date).day() + 1);
                  console.log(moment(val.date).day() + 1);
                  setLoading(true);
                }}
                isSelected={SelectedDate === val.date}
              />
            ))}
            {week !== 2 && (
              <button onClick={() => setWeek(week + 1)}>
                <RightArrow />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-lg mx-5 md:ml-7 md:mr-8 md:mb-2 pt-4 text-sm font-medium text-center text-queueText border-b border-sbBorder h-full">
        <Tabs
          tabsConfig={
            moment(SelectedDate, "YYYY-MM-DD").isBefore(moment().startOf("day"))
              ? tabsConfig
              : SelectedDate === moment().format("YYYY-MM-DD")
              ? todayTabsConfig
              : futureTabsConfig
          }
          rightSide={
            <div className="flex justify-end items-center m-3 md:pr-5 md:mt-0 md:mb-[7px]">
              {/* {bookings === undefined ? (
                <p className="text-queueRed mr-3">Queue has not started</p>
              ) : ( */}

              <button
                className="downloadBtn bg-sbTextHover hover:bg-queueHover opacity-80"
                onClick={handleDownloadExcel}
              >
                <svg
                  className="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span className="icon2"></span>
                <span className="tooltip">Download Excel</span>
              </button>

              <button
                className={`rounded-lg opacity-80  px-4 py-2 text-white hover:bg-queueHover mr-3
                ${
                  moment(SelectedDate, "YYYY-MM-DD").isBefore(
                    moment().startOf("day")
                  ) || session === undefined
                    ? "bg-queueHover cursor-not-allowed"
                    : "bg-sbTextHover"
                }`}
                onClick={() => {
                  // session === undefined
                  //   ? toast.error("Please select a session")
                  //   : moment(SelectedDate, "YYYY-MM-DD").isBefore(
                  //       moment().startOf("day")
                  //     )
                  //   ? toast.error("Cannot make bookings for the past.")
                  //   : setVisible(true);
                  if (
                    !(
                      moment(SelectedDate, "YYYY-MM-DD").isBefore(
                        moment().startOf("day")
                      ) || session === undefined
                    )
                  ) {
                    navigate(
                      "/" + hospitalID + "/appointments/book-an-appointment",
                      {
                        state: {
                          doctor_id: docDetails?.doctor_id,
                        },
                      }
                    );
                  }
                }}
              >
                Create Appointment
              </button>

              <ReactDropdown
                options={options ? options : []}
                onChange={changeOption}
                value={options?.length === 0 ? "No sessions today" : session}
                placeholder={"Select a session"}
                controlClassName="!rounded-lg"
              />
            </div>
          }
        />
      </div>
      <AddBookingModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        session={session}
        setSession={setSession}
        queue_type={queue_type}
        setQueue_type={setQueue_type}
        options={options}
      />
      <FollowUpModal
        modalIsOpen={followUpVisible}
        closeModal={() => setFollowUpVisible(false)}
        customStyles={modalStyles}
        session={session}
        booking_id={bookingId}
      />
      <RescheduleModal
        modalIsOpen={rescheduleVisible}
        closeModal={() => setRescheduleVisible(false)}
        customStyles={modalStyles}
        session={session?.value}
        booking_id={bookingId}
        type="each"
        booking_ids={[]}
        mappingId={undefined}
      />
      <UploadReport
        modalIsOpen={uploadVisible}
        closeModal={() => setUploadVisible(false)}
        customStyles={modalStyles}
        booking_id={bookingId}
        patient_id={patientId}
        availability_id={String(session?.value)}
      />
      <PaymentModal
        modalIsOpen={paymentVisible}
        closeModal={() => setPaymentVisible(false)}
        customStyles={modalStyles}
        booking_id={bookingId}
        patient_id={patientId}
        docDetails={docDetails}
      />
      <EditPatientModal
        modalIsOpen={editVisible}
        closeModal={() => setEditVisible(false)}
        customStyles={modalStyles}
        bookingData={bookings}
        fetchBookingsData={() => fetchBookingsData(String(session?.value))}
      />
      <PrescriptionModal
        modalIsOpen={prescriptionVisible}
        closeModal={() => setPrescriptionVisible(false)}
        customStyles={modalStyles}
        bookings={bookings}
        docDetails={docDetails}
        booking_id={bookingId}
      />

      {(userData === undefined || loading) && <Loader />}
    </div>
  );
};

export default EachDoctor;
