import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { AvailabilitySlot, EditDaySessions } from "../../../services/types";
import EditSession from "../../molecules/EditSession";
import moment from "moment";
import { useParams } from "react-router-dom";
import { updateAvailability } from "../../../lib/utils/funcs";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import { updateAvailabilityBulk } from "../../../lib/apis/doctor";
import WhiteButton from "../../atoms/buttons/WhiteButton";

const EditSessionModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  docAvail,
  setDocAvail,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  docAvail: AvailabilitySlot[] | undefined;
  setDocAvail: React.Dispatch<
    React.SetStateAction<AvailabilitySlot[] | undefined>
  >;
}) => {
  const defaultDaySessions: EditDaySessions = {
    sessions: {
      morning: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        is_active: false,
        max_wa_tokens: null,
        queue_type: "",
        // token_type: "",
      },
      afternoon: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        is_active: false,
        max_wa_tokens: null,
        queue_type: "",
        // token_type: "",
      },
      evening: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        is_active: false,
        max_wa_tokens: null,
        queue_type: "",
        // token_type: "",
      },
    },
    selected: false,
  };

  const param = useParams();
  const mapping_id = String(param.mapping_id);
  const [weekSchedule, setWeekSchedule] = useState<{
    [key: number]: EditDaySessions;
  }>({
    1: {
      ...defaultDaySessions,
      sessions: {
        morning: {
          ...defaultDaySessions.sessions.morning,
          day_of_week: 1,
          selected: false,
        },
        afternoon: {
          ...defaultDaySessions.sessions.afternoon,
          day_of_week: 1,
          selected: false,
        },
        evening: {
          ...defaultDaySessions.sessions.evening,
          day_of_week: 1,
          selected: false,
        },
      },
      selected: false,
    },
    2: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 2 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
      },
      selected: false,
    },
    3: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 3 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
      },
      selected: false,
    },
    4: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 4 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
      },
      selected: false,
    },
    5: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 5 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
      },
      selected: false,
    },
    6: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 6 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
      },
      selected: false,
    },
    7: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 7 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
      },
      selected: false,
    },
  });

  const setData = (data: AvailabilitySlot[] | undefined) => {
    const temp: {
      [key: number]: EditDaySessions;
    } = {
      1: {
        ...defaultDaySessions,
        sessions: {
          morning: {
            ...defaultDaySessions.sessions.morning,
            day_of_week: 1,
            selected: false,
          },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 1,
            selected: false,
          },
          evening: {
            ...defaultDaySessions.sessions.evening,
            day_of_week: 1,
            selected: false,
          },
        },
        selected: false,
      },
      2: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 2,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
        },
        selected: false,
      },
      3: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 3,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
        },
        selected: false,
      },
      4: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 4,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
        },
        selected: false,
      },
      5: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 5,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
        },
        selected: false,
      },
      6: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 6,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
        },
        selected: false,
      },
      7: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 7,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
        },
        selected: false,
      },
    };
    if (data !== undefined)
      for (const availability of data) {
        const {
          availability_id,
          day_of_week,
          start_time,
          end_time,
          max_patients_per_slot,
          wait_time,
          max_wa_tokens,
          queue_type,
          // token_type,
        } = availability;

        if (temp[day_of_week]) {
          temp[day_of_week].selected = true;
          if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("05:59:00", "HH:mm:ss"),
              moment("11:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.morning.availability_id =
              availability_id;
            temp[day_of_week].sessions.morning.start_time = start_time;
            temp[day_of_week].sessions.morning.end_time = end_time;
            temp[day_of_week].sessions.morning.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.morning.wait_time = wait_time;
            temp[day_of_week].sessions.morning.selected = true;
            temp[day_of_week].sessions.morning.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.morning.queue_type = queue_type;
            // temp[day_of_week].sessions.morning.token_type = token_type;
          } else if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("11:59:00", "HH:mm:ss"),
              moment("16:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.afternoon.availability_id =
              availability_id;
            temp[day_of_week].sessions.afternoon.start_time = start_time;
            temp[day_of_week].sessions.afternoon.end_time = end_time;
            temp[day_of_week].sessions.afternoon.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.afternoon.wait_time = wait_time;
            temp[day_of_week].sessions.afternoon.selected = true;
            temp[day_of_week].sessions.afternoon.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.afternoon.queue_type = queue_type;
            // temp[day_of_week].sessions.afternoon.token_type = token_type;
          } else {
            temp[day_of_week].sessions.evening.availability_id =
              availability_id;
            temp[day_of_week].sessions.evening.start_time = start_time;
            temp[day_of_week].sessions.evening.end_time = end_time;
            temp[day_of_week].sessions.evening.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.evening.wait_time = wait_time;
            temp[day_of_week].sessions.evening.selected = true;
            temp[day_of_week].sessions.evening.max_wa_tokens = max_wa_tokens;
            temp[day_of_week].sessions.evening.queue_type = queue_type;
            // temp[day_of_week].sessions.evening.token_type = token_type;
          }
        }
      }

    setWeekSchedule({ ...temp });
  };

  useEffect(() => {
    setData(docAvail);
  }, [modalIsOpen]);

  const update = updateAvailability(weekSchedule, mapping_id);
  // console.log('updateeeeee', update)

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Week Pattern</p>
        <CloseButton handleClick={closeModal} />
      </div>

      {/* Body */}
      <div className="p-0 max-h-96 overflow-y-auto">
        <div className="px-6 py-5 bg-lightGrey">
          <EditSession schedule={weekSchedule} setSchedule={setWeekSchedule} video={false}/>
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
        <WhiteButton
          name="Cancel"
          className="w-full h-14"
          handleClick={closeModal}
        />
        <DarkBlueButton
          name="Save"
          className="w-full h-14"
          handleClick={async () => {
            if (update.length !== 0) {
              console.log(update);

              const res = await updateAvailabilityBulk(mapping_id, update);
              if (res?.status === 201) {
                console.log(res.data.result);
                setDocAvail(res.data.result);
                setData(res.data.result);
                closeModal();

                toast.success("Sessions updated!");
              }
            } else {
              toast.error("Unexpected error occured.");
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default EditSessionModal;
